import Vue from "vue";
import Router from "vue-router";
import appConfig from "../../src/app.config";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Home",
      path: "/",
      component: () => import("./views/home"),
      meta: {
        title: appConfig.title + " › Home",
        authRequired: true,
        keepAlive: false,
        access: ["limited", "full"]
      },
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("./views/login"),
      meta: {
        title: appConfig.title + " › Login",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Logging in",
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: {
        title: appConfig.title + " › Logging in",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Sair",
      path: "/logout",
      redirect: "/login",
    },
    {
      name: "Cadastre-se",
      path: "/register",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title + " › Cadastre-se",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Validação de e-mail",
      path: "/email/:token",
      component: () => import("./views/account/email-validation"),
      meta: {
        title: appConfig.title + " › Validação de e-mail",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Cadastre-se › Patrocinador",
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: {
        title: appConfig.title + " › Cadastre-se › Patrocinador",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Esqueceu sua senha?",
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: {
        title: appConfig.title + " › Esqueceu sua senha?",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Alterar senha",
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: {
        title: appConfig.title + " › Alterar senha",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      name: "Planos",
      path: "/upgrade",
      component: () => import("./views/upgrade"),
      meta: {
        title: appConfig.title + " › Planos",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Minha Conta",
      path: "/account",
      component: () => import("./views/account/account"),
      meta: {
        title: appConfig.title + " › Minha Conta",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Meus Documentos",
      path: "/account/documents",
      component: () => import("./views/account/documents"),
      meta: {
        title: appConfig.title + " › Meus Documentos",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Meus Endereços",
      path: "/account/address",
      component: () => import("./views/account/address"),
      meta: {
        title: appConfig.title + " › Meus Endereços",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Notificações",
      path: "/account/notifications",
      component: () => import("./views/account/notifications"),
      meta: {
        title: appConfig.title + " › Notificações",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Minha Senha",
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: {
        title: appConfig.title + " › Minha Senha",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Senha de Segurança",
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: {
        title: appConfig.title + " › Senha de Segurança",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Senha de Segurança › Recuperar",
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: {
        title: appConfig.title + " › Senha de Segurança › Recuperar",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Conta Bancária",
      path: "/account/bank",
      component: () => import("./views/account/bank"),
      meta: {
        title: appConfig.title + " › Conta Bancária",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "PIX",
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: {
        title: appConfig.title + " › PIX",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Investimentos › Depósito",
      path: "/investment/deposit",
      component: () => import("./views/investment/deposit"),
      meta: {
        title: appConfig.title + " › Investimentos › Depósito",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Investimentos ›  Meus iInvestimentos",
      path: "/investment/orders",
      component: () => import("./views/investment/orders"),
      meta: {
        title: appConfig.title + " › Investimentos › Meus iInvestimentos",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Investimentos › Pedido",
      path: "/investment/order/:id",
      component: () => import("./views/investment/order"),
      meta: {
        title: appConfig.title + " › Investimentos › Pedido",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      }
    },
    {
      name: "Carteira › Extrato",
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract-network"),
      meta: {
        title: appConfig.title + " › Carteira › Extrato",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Carteira › Transferir",
      path: "/wallet/transfer",
      component: () => import("./views/wallet/transfer"),
      meta: {
        title: appConfig.title + " › Carteira › Transferir",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Carteira › Pagar",
      path: "/wallet/pay",
      component: () => import("./views/wallet/pay"),
      meta: {
        title: appConfig.title + " › Carteira › Pagar",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Carteira › Saque",
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: {
        title: appConfig.title + " › Carteira › Saque",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Carteira › Saque › Histórico",
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: {
        title: appConfig.title + " › Carteira › Saque › Histórico",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Ativação › Extrato",
      path: "/wallet/activation/extract",
      component: () => import("./views/wallet/activation-extract"),
      meta: {
        title: appConfig.title + " › Ativação › Extrato",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Ativação › Saldo",
      path: "/wallet/activation/balance",
      component: () => import("./views/wallet/activation-balance"),
      meta: {
        title: appConfig.title + "Ativação › Saldo",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Ativação › Voucher",
      path: "/wallet/activation/voucher",
      component: () => import("./views/wallet/activation-voucher"),
      meta: {
        title: appConfig.title + "Ativação › Voucher",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Rede › Indicados",
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: {
        title: appConfig.title + " › Rede › Indicados",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Rede › Equipe",
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: {
        title: appConfig.title + " › Rede › Equipe",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Rede › Binário",
      path: "/network/binary",
      component: () => import("./views/network/binary"),
      meta: {
        title: appConfig.title + " › Rede › Binário",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      }
    },
    {
      name: "Rede › Binário › Arvore",
      path: "/network/binary/:id",
      component: () => import("./views/network/binary"),
      meta: {
        title: appConfig.title + " › Rede › Binário",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      }
    },
    {
      name: "Relatórios › Indicação",
      path: "/reports/referral",
      component: () => import("./views/reports/referral"),
      meta: {
        title: appConfig.title + " › Relatórios › Indicação",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Relatórios › Binário",
      path: "/reports/binary",
      component: () => import("./views/reports/binary"),
      meta: {
        title: appConfig.title + " › Relatórios › Binário",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      }
    },
    {
      name: "Relatórios › Compras",
      path: "/reports/shopping",
      component: () => import("./views/reports/shopping"),
      meta: {
        title: appConfig.title + " › Relatórios › Compras",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Relatórios › Graduação",
      path: "/reports/graduation",
      component: () => import("./views/reports/graduation"),
      meta: {
        title: appConfig.title + " › Relatórios › Graduação",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Relatórios › Dividendos",
      path: "/reports/profit",
      component: () => import("./views/reports/profit"),
      meta: {
        title: appConfig.title + " › Relatórios › Dividendos",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Loja › Destaques",
      path: "/store/featured",
      component: () => import("./views/store/featured"),
      meta: {
        title: appConfig.title + " › Loja › Destaques",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Loja › Categoria",
      path: "/store/category/:id",
      component: () => import("./views/store/category"),
      meta: {
        title: appConfig.title + " › Loja › Categoria",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Loja › Carrinho",
      path: "/store/cart",
      component: () => import("./views/store/cart"),
      meta: {
        title: appConfig.title + " › Loja › Carrinho",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Loja › Pedidos",
      path: "/store/orders",
      component: () => import("./views/store/orders"),
      meta: {
        title: appConfig.title + " › Loja › Pedidos",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Loja › Pedido",
      path: "/store/order/:id",
      component: () => import("./views/store/order"),
      meta: {
        title: appConfig.title + " › Loja › Pedido",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Downloads",
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: {
        title: appConfig.title + " › Downloads",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "FAQ",
      path: "/faq",
      component: () => import("./views/faq"),
      meta: {
        title: appConfig.title + " › FAQ",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Blog",
      path: "/blog/home",
      component: () => import("./views/blog/home"),
      meta: {
        title: appConfig.title + " › Blog",
        authRequired: true,
        keepAlive: false,
        access: ["full"]
      },
    },
    {
      name: "Tickets",
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: {
        title: appConfig.title + " › Tickets",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Tickets › Ver",
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: {
        title: appConfig.title + " › Tickets › Ver",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Cursos",
      path: "/courses",
      component: () => import("./views/courses/list"),
      meta: {
        title: appConfig.title + " › Cursos",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Cursos › Ver",
      path: "/courses/:course",
      component: () => import("./views/courses/view"),
      meta: {
        title: appConfig.title + " › Cursos",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },
    {
      name: "Cursos › Ver › Aula",
      path: "/courses/:course/:module/:lesson",
      component: () => import("./views/courses/view"),
      meta: {
        title: appConfig.title + " › Cursos",
        authRequired: true,
        keepAlive: false,
        access: ["full", "limited"]
      },
    },

    {
      name: "404",
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        title: appConfig.title + " › 404",
        authRequired: false,
        keepAlive: false,
      },
    },
    {
      path: "*",
      redirect: "/register*",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          if (
            response.data.order &&
            response.data.order.id &&
            to.name != "Loja › Pedido"
          ) {
            return next("/store/order/" + response.data.order.id);
          } else if (
            response.data.user.package.upgrade == "exceeded" &&
            to.name != "Planos" &&
            to.name != "Loja › Pedido"
          ) {
            return next("/upgrade");
          } else if (!to.meta.access.includes(response.data.user.package.access)) {
            return next("/");
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  }

  document.title = to.meta.title;
  next();
});

export default router;
